.policies-regulations {
    padding: 2rem;
    gap: 1rem;
    height: 100%;
    flex-shrink: 0;
    position: relative;

    .card-box {
        width: fit-content;
        overflow: auto;
    }

    .policies-regulations__header {
        font-size: 3rem;
    }

    .policies-regulations__description {
        font-size: 1.5rem;
        white-space: nowrap;
    }

    .policies-regulations__body {
        flex: 1;
        gap: 1.25rem;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .policies-regulations-item {
        gap: 0.75rem;
        width: 100%;

        .policies-regulations-item__title {
            font-size: 1.5rem;
        }

        .policies-regulations-item__paragraph {
            font-size: 1rem;
        }
    }
}

@media (max-width: 975px) and (min-width: 451px) {
    .policies-regulations {
        padding: 1rem;
    }

    .policies-regulations__header {
        font-size: 2rem !important;
        white-space: wrap !important;
    }

    .policies-regulations__description {
        font-size: 1.5rem !important;
        white-space: wrap !important;
    }
}

@media (max-width: 450px) {
    .policies-regulations {
        padding: 0.75rem;
    }

    .policies-regulations__header {
        font-size: 1.5rem !important;
        padding-bottom: 0.25rem;
    }

    .policies-regulations__description {
        font-size: 1rem !important;
        white-space: wrap !important;
    }

    .policies-regulations-item__title {
        font-size: 1rem !important;
    }
}