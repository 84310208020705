.card-container {
    flex-grow: 1;
    margin: 1rem;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.45);
    transition: all 0.5s;
    padding: 1rem;

    &:hover {
        background: rgba(255, 255, 255, 0.75);
        transition: all 0.5s;
        cursor: pointer;
    }

    &:active {
        background: rgba(217, 217, 217, 0.75);
    }

    .card-image {
        width: 7rem;
        height: 7rem;
        align-self: center;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .card-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        text-align: start;
        padding: 1rem;

        .card-content__header {
            font-size: xx-large
        }

        .card-content__description {
            font-size: 1.25rem;
            vertical-align: middle;
        }
    }
}

@media (max-width: 975px) {
    .card-image {
        width: 8rem !important;
        height: 8rem !important;
    }

    .card-content * {
        font-size: x-large !important;
    }
}

@media (max-width: 400px) {
    .card-content * {
        padding: 0.5rem !important;
        font-size: large !important;
    }

    .card-image {
        width: 4.5rem !important;
        height: 4.5rem !important;
    }
}

@media (max-width: 300px) {
    .card-image {
        display: none !important;
    }
}