.services {
    padding: 2rem;
    gap: 1rem;
    height: 100%;
    flex-shrink: 0;
    position: relative;

    .card-box {
        width: fit-content;
    }

    .services__header {
        font-size: 3rem;
    }

    .services__description {
        font-size: 1.5rem;
        white-space: nowrap;
    }

    .services__body {
        gap: 1rem;
        overflow: auto;
        padding-inline-end: 0.25rem;
        border-radius: 0.5rem;
    }

    .services-item {
        gap: 1.5rem;
        width: 100%;

        .header {
            font-size: 2rem;
        }
    }
}

@media (max-width: 975px) and (min-width: 401px) {
    .services {
        padding: 1rem;

        .card-box {
            width: auto !important;
        }
    }

    .services__header {
        font-size: 2.5rem !important;
    }

    .services__description {
        font-size: 1.5rem !important;
    }
}

@media (max-width: 450px) {
    .services {
        padding: 0.75rem;
    }

    .services__header {
        font-size: 2.5rem !important;
    }

    .services__description {
        font-size: 1.5rem !important;
        white-space: wrap !important;
    }

    .description-header {
        font-size: 1rem !important;
    }
}