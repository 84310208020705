@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.component-header {
    width: fit-content;
}

.topview-container {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    height: 100%;
    flex-shrink: 0;
    position: relative;

    .topview-container__info {
        flex: 1;
        text-align: start;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        padding-bottom: 2.75rem;
        gap: 1rem;

        .topview-container__info__header {
            font-size: 3rem;
        }

        .topview-container__info__title {
            font-size: 2rem;
        }


        &.home-page-variant {

            justify-content: center;

            .topview-container__info__header {
                font-size: 4rem;
            }

            .topview-container__info__title {
                font-size: 3rem;
            }
        }

        .topview-container__info__description {
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            // .header {
            //     font-size: 1.5rem;
            // }

            .description {
                font-size: 1.125rem;
                align-items: center;
                line-height: 1.75rem;
            }
        }
    }

    .img-container {
        flex: 1;
        flex-grow: 0.5;
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: end;
        position: relative;
        overflow: hidden;

        img {
            justify-content: center;
            position: absolute;
        }

        .portrait-img-container {
            border-radius: 50%;
            align-self: center;
            z-index: 2;
            overflow: hidden;
            background-repeat: round;
            border: 0.25rem solid #d1dfe8;
            align-items: self-end;
            background-image: url("/assets/images/portraitimgbackground.png");
            display: flex;
            justify-content: center;
            aspect-ratio: 1/1;

            img.portrait {
                animation: fadeIn 1s;
                position: relative;
                transition: all;
            }
        }


        img.background-1 {
            z-index: 1;
            right: 0;
            top: 0;
        }

        img.background-2 {
            z-index: 1;
            left: 0;
            bottom: 0;
            rotate: 180deg;
        }
    }

    .differently-sized-boxes {
        height: 100%;
        display: grid;
        gap: 1rem;
        grid-template:
            "box1 box2"
            "box1 box3";

        &.home {
            height: fit-content;
            display: flex;
        }

        .card-box {
            padding: 1rem;
        }

        .box1 {
            grid-area: box1;
        }

        .box2 {
            grid-area: box2;
        }

        .box3 {
            grid-area: box3;
        }

        .box4 {
            grid-area: box4;
        }

        .box5 {
            grid-area: box5;
        }

        &>* {
            justify-self: stretch;
        }
    }
}

@media (max-height: 875px) or (max-width: 1750px) {
    .topview-container.about-me-variant {
        height: fit-content;
        flex-direction: column !important;

        .topview-container__info {}

        .differently-sized-boxes {
            height: fit-content;
            grid-template:
                "box1"
                "box2"
                "box3";
        }

        .topview-container__info__description {
            .description {
                font-size: 1rem !important;
            }
        }

        .img-container {
            width: 100% !important;
            padding-bottom: 2.75rem !important;
            padding-top: 2rem !important;

            .portrait-img-container {
                height: 24rem;
                width: 24rem;
            }
        }
    }
}

@media (max-width: 975px) or (max-height: 650px) {
    .topview-container {
        height: auto;
        flex-direction: column !important;

        .topview-container__info {
            padding: 0.75rem;
            width: 100% !important;
            padding-bottom: 2rem;
        }

        .topview-container__info.home-page-variant {
            justify-content: start;
        }

        .img-container {
            width: 100% !important;
            padding-bottom: 2.75rem !important;
            padding-top: 2rem !important;
            flex: unset !important;

            .portrait-img-container {
                height: 24rem;
                width: 24rem;
            }
        }
    }
}

@media (max-width: 975px) and (min-width: 401px) {
    .topview-container__info__header {
        font-size: 3rem !important;
    }

    .topview-container__info__title {
        font-size: 2rem !important;
    }
}

@media (max-width: 400px) {
    .topview-container__info {
        padding: 0.75rem !important;
        padding-bottom: 0.75rem !important;
    }

    .topview-container__info__header {
        font-size: 2.5rem !important;
    }

    .topview-container__info__title {
        font-size: 1.75rem !important;
    }

    .topview-container__info__description {

        .description {
            font-size: 1rem !important;
        }
    }
}

@media (max-width: 500px) {
    .portrait-img-container {
        height: 100% !important;
    }
}