.new-patients {
    padding: 2rem;
    gap: 1rem;
    height: 100%;
    flex-shrink: 0;
    position: relative;

    .card-box {
        width: fit-content;
    }

    .new-patients__header {
        font-size: 3rem;
    }

    .new-patients__description {
        font-size: 1.5rem;
        white-space: nowrap;
    }

    .new-patients__body {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .luminello-link {
            width: 100%;
            overflow: hidden;
        }

        .luminello-img-container {
            width: auto;
            height: 3rem;
            margin-block-end: 2rem;
        }

        .text-item {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            // .text-item__paragraph {}
        }
    }

    .new-patients__body.card-box {
        padding-inline: 5rem;
        padding-block: 3rem;
    }
}

@media (max-width: 1501px) or (max-height: 750px) {
    .new-patients {
        height: auto !important;
    }

    .new-patients__title-box {
        width: auto !important;
    }

    .new-patients__body {
        position: relative !important;
        top: unset !important;
        left: unset !important;
        transform: unset !important;
        width: 100% !important;
    }

    .luminello-img-container {
        height: 2rem !important;
    }

    .new-patients__body {
        padding-inline: 2rem !important;
        padding-block: 1rem !important;
    }
}


@media (max-width: 1500px) and (min-width: 401px) {

    .new-patients {
        padding: 1rem;
    }

    .new-patients__header {
        font-size: 2.5rem !important;
    }

    .new-patients__description {
        font-size: 1.5rem !important;
    }
}

@media (max-width: 450px) {

    .new-patients {
        padding: 0.75rem;
    }

    .new-patients__header {
        font-size: 1.5rem !important;
        padding-bottom: 0.25rem;
    }

    .new-patients__description {
        font-size: 1rem !important;
        white-space: wrap !important;
    }

    .new-patients-item__title {
        font-size: 1rem !important;
    }

    .new-patients__body {
        padding-inline: 1.25rem !important;
        padding-block: 1rem !important;
    }
}