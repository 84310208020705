.contact-info {
    padding: 2rem;
    gap: 1rem;
    height: 100%;
    flex-shrink: 0;
    position: relative;

    .card-box {
        width: fit-content;
    }

    .contact-info__header {
        font-size: 3rem;
    }

    .contact-info__description {
        font-size: 1.5rem;
        white-space: nowrap;
    }

    .contact-info__body {
        display: flex;
        gap: 1rem;
        width: 100%;
        height: 100%;
        justify-content: space-between;

        .contact-info__body__item {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            text-align: center;
            align-items: center;
            flex: 1;
            height: 100%;
            min-width: 0;
            padding-top: 6rem;
            background: linear-gradient(171deg, rgba(235, 246, 255, 1) 10%,
                    rgba(241, 249, 255, 1) 27%,
                    rgba(246, 251, 255, 1) 63%,
                    rgba(255, 255, 255, 1) 79%);

            img {
                height: auto;
                width: 16rem;
                padding-bottom: 3rem;
            }

            .item-text-content-wrapper {
                display: flex;
                flex-direction: column;
                row-gap: 3.5rem;
                width: 100%;
            }

            .MuiChip-root {
                width: fit-content;
                align-self: center;
                background-color: #bde1ff;
                height: auto;
                border-radius: 2rem;
                padding-block: 0.25rem;
            }

            .MuiChip-label {
                font-size: 1.125rem;
                font-weight: bold;
                overflow: unset;
                white-space: break-spaces;
                text-overflow: unset;
            }

            .phone-number-chip {
                font-size: 0.25rem;
                padding-block: unset;

                .MuiAvatar-root {
                    width: auto;
                    height: auto;
                    border-radius: unset;
                }

                .MuiChip-label {
                    padding-left: unset;
                }

                img {
                    padding: unset;
                    width: 9rem;
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 975px) {
    .contact-info {
        height: auto;

        .contact-info__body {
            align-items: center;
            flex-direction: column;

            .contact-info__body__item {
                width: 100%;
            }
        }

        .phone-number-chip {
            font-size: 0.25rem;

            .MuiAvatar-root {
                width: auto;
                height: auto;
                border-radius: unset;
            }

            .MuiChip-label {
                padding-left: unset;
            }

            img {
                padding: unset;
                width: 10rem;
                height: auto;
            }
        }
    }
}

@media (max-width: 975px) and (min-width: 401px) {
    .contact-info {
        padding: 0.75rem !important;
    }

    .contact-info__header {
        font-size: 2.5rem !important;
    }
    
    .contact-info__description {
        font-size: 1.5rem !important;
        white-space: wrap !important;
    }

    .MuiChip-label {
        font-size: 1rem !important;
    }
    
    .phone-number-chip {
        img {
            width: 8rem !important;
        }
    }
}

@media (max-width: 400px) {
    .contact-info {
        padding: 0.75rem;
    }

    .contact-info__header {
        font-size: 2rem !important;
        padding-bottom: 0.25rem;
    }

    .contact-info__description {
        font-size: 1.125rem !important;
        white-space: wrap !important;
    }

    .MuiChip-label {
        font-size: 0.75rem !important;
    }

    .phone-number-chip {
        img {
            width: 6rem !important;
        }
    }
}