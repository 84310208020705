.not-found {
    text-align: start;
    padding: 2rem;
    height: 100%;
    flex-shrink: 0;
    position: relative;

    h1 {
        font-size: 5rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    font-size: xx-large !important;
}