* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto";
}

html,
body,
#root {
	height: 100%;
	width: 100%;
}

.App {
	background: linear-gradient(180deg, rgba(31, 117, 254, 1) 0%, rgba(102, 164, 254, 1) 25%, rgba(175, 213, 255, 1) 71%, rgba(199, 229, 255, 1) 87%, rgba(215, 236, 255, 1) 98%);
	height: 100%;
	text-align: center;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.back-to-top-button {
		position: absolute;
		bottom: 0;
		right: 0;
		width: fit-content;
		margin-bottom: 0.1875rem;
		margin-right: 1rem;
		background-color: #66a6d6;
		z-index: 10;
		transition: visibility 0.25s, opacity 0.25s;
	
		&:hover {
			background-color: #66a6d6;
		}
	}
}

img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	display: block;
}

.bold {
	font-weight: bold;
}

.app-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.card-box {
	background: rgba(255, 255, 255, 0.85);
	border-radius: 0.5rem;
	box-shadow: 0 0.25rem 30px rgba(0, 0, 0, 0.1);
	text-align: start;
	padding: 1.5rem;
	height: fit-content;
}

.color--blue {
	color: #277afe;
}

.flex--row {
	display: flex;
	flex-direction: row;
}

.flex--column {
	display: flex;
	flex-direction: column;
}

.main-background-img {
	background-image: url("assets/images/background.jpg");
	background-repeat: no-repeat;
	background-size: cover;
}

.under-construction {
	width: 100% !important;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
}

::-webkit-scrollbar {
	width: 0.75rem;
	height: 0.75rem;
}

::-webkit-scrollbar-thumb {
	background: rgb(39, 122, 254, 0.75);
	border-radius: 0.5rem;
	width: 0.65rem;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	background: whitesmoke;
	width: 1rem;
	border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(36, 103, 211, 0.75);
}

.white-space--nowrap {
	white-space: nowrap;
}

.hidden {
	display: none !important;
}

.invisible {
	visibility: hidden;
	opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@media (max-width: 975px) {
	.app-content {
		height: auto !important;
	}
}